export const NAV_LINKS = [
  {
    key: "faq",
    url: "/faq",
    label: "common:links.faq",
  },
  {
    key: "contact",
    url: "/contact",
    label: "common:links.contact",
  },
];